import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { ParallaxProvider } from 'react-scroll-parallax';
import _ from 'lodash';
import {sanitizeParagraphs} from 'utils/html';
import Base from 'Layouts/Base';
import ParallaxShape from 'Components/ParallaxShape';
import SearchForm from 'Components/SearchForm';
import s from './NotFound.module.scss';

const NotFound = ({title, subTitle, text, link, searchUrl}) => {
    return (
        <div className={s['NotFound']}>
            <ParallaxProvider>
                <ParallaxShape
                    className={s['NotFound__ParallaxShape']}
                    side="Right"
                    size="Small"
                    y={['-15%', '15%']}
                />
                <div className={s['NotFound__Wrap']}>
                    <div className={s['NotFound__Content']}>
                        {title &&
                            <h1 className={s['NotFound__Title']}>{title}</h1>
                        }
                        {subTitle &&
                            <h2 className={s['NotFound__SubTitle']}>{subTitle}</h2>
                        }
                        {text &&
                            <div
                                className={s['NotFound__Text']}
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeParagraphs(text),
                                }}
                            />
                        }
                        {!_.isEmpty(link) &&
                            <Link {...link}/>
                        }
                        {!_.isEmpty(searchUrl) &&
                            <div className={s['NotFound__SearchForm']}>
                                <SearchForm
                                    url={searchUrl}
                                    id="not-found-search"
                                    modifier="notFound"
                                />
                            </div>
                        }
                    </div>
                </div>
                <ParallaxShape
                    className={s['NotFound__ParallaxShape']}
                    side="Right"
                    size="Small"
                    y={['-15%', '15%']}
                />
            </ParallaxProvider>
        </div>
    );
};

NotFound.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    text: PropTypes.string,
    searchUrl: PropTypes.string,
    link: PropTypes.object,
};

NotFound.defaultProps = {
    title: '',
    subTitle: '',
    text: '',
    searchUrl: '',
    link: {},
};

const Link = ({url, target}) => {
    const {t} = useTranslation();
    const text = t('notFound.link');
    return (
        <a className={s['NotFound__Button']} href={url} target={target}>{text}</a>
    );
};

Link.propTypes = {
    url: PropTypes.string,
    target: PropTypes.string,
};

Link.defaultProps = {
    url: '',
    target: '',
};

export default Base(NotFound);
